import React from "react"
import Image from "../assets/images/time-calendar.svg"
import Mindefulness from "../assets/images/mindfulness.svg"
import SavingsImage from "../assets/images/savings.svg"
import Layout from "../components/Layout/Layout"
import RegistrationCardForm from "../components/RegistrationCardForm"

const TestPage = () => (
  <Layout>
    <header className="header"
            style={{ backgroundImage: "linear-gradient(135deg, #f7fcfb 0%, #fff 50%, #effcfa 100%)" }}>
      <div className="container">
        <div className="row align-items-center">

          <div className="col-lg-6">
            <h1>Ontvang eenvoudig <u>compensaties</u> voor <u>treinvertraging</u></h1>
            <p className="lead mt-5 mb-8">Via weertelaat.be ontvang je eenvoudig <strong>financiële
              compensaties</strong> voor <strong>trein vertragingen</strong> langer dan 15 minuten. Laat ons
              weten tussen welke stations jij pendelt, wij doen de rest! </p>
            <p className="gap-xy">
              <a className="btn btn-lg btn-round btn-primary" href="#">Hoe werkt het?</a>
              <a className="btn btn-lg btn-round btn-outline-secondary" href="#">Inschrijven</a>
            </p>
          </div>

          <div className="col-lg-5 ml-auto d-none d-lg-block">
            <img src={Image} alt="img"/>
          </div>

        </div>
      </div>
    </header>


    <section className="section overflow-hidden">
      <div className="container">
        <header className="section-header">
          <h2>Treinreizigers met meer dan 15 minuten vertraging hebben recht op compensatie.</h2>
          <hr/>
          <p className="lead">
            Veel pendelaars weten niet dat er een compensatieregeling bestaat voor trein vertragingen. In principe
            kan <strong>elke reiziger met een vertraging van 15 minuten of meer een compensatie aanvraag
            indienen</strong> bij de Belgische
            spoorwegen. Vertragingen van 15 minuten tot 59 minuten moeten herhaaldelijk zijn voorgekomen op hetzelfde
            traject binnen een periode van 6 maanden.
          </p>
        </header>


        <div className="row gap-y text-center">

          <div className="col-md-4 d-flex flex-column">
            <div className="mb-7">
              <p className="text-info lead-7 mb-0">+15 min.</p>
              <p className="lead-3">Terugbetaling van 25%</p>
              <p className="">Min. 20 vertragingen op 6 maanden</p>
            </div>
          </div>

          <div className="col-md-4 d-flex flex-column">
            <div className="mb-7">
              <span className="text-info lead-7">+30 min.</span><br/>
              <p className="lead-3">Terugbetaling van 50%</p>
              <p className="">Min. 10 vertragingen op 6 maanden</p>
            </div>
          </div>

          <div className="col-md-4 d-flex flex-column">
            <div className="mb-7">
              <span className="text-info lead-7">+60 min.</span><br/>
              <p className="lead-3">Volledige terugbetaling</p>
              <p className="">Elke vertraging geeft recht op compensatie</p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section className="section bg-light">
      <div className="container">

        <div className="row gap-y align-items-center">
          <div className="col-md-5 ml-auto">
            <h3>Waarom weertelaat.be?</h3>
            <p>De huidige procedure om een compensatie te bekomen voor trein vertragingen is complex en langdurig. Net
              daarom zijn er maar weinig mensen die momenteel een compensatie voor treinvertraging aanvragen.
            </p>
            <p>
              Weertelaat.be is een digitaal platform waarmee je een <strong>compensatie voor treinvertraging (en)
              volledig kan automatiseren.</strong> Zo ontvang je eenvoudig compensaties waar je recht op hebt!
            </p>
          </div>

          <div className="col-md-5 text-center order-md-first">
            <img src={Mindefulness} alt="..."/>
          </div>
        </div>


        <hr className="my-8"/>


        <div className="row gap-y align-items-center">
          <div className="col-md-5 mr-auto">
            <h3>Compensatie voor treinvertraging</h3>
            <p>De compensatie voor treinvertraging bestaat uit een <strong>financiële vergoeding gebaseerd op:</strong>
            </p>
            <ul>
              <li>Aantal vertragingen</li>
              <li>Totaal aantal minuten per vertraging</li>
              <li>Kostprijs van je abonnement of ticket(s)</li>
            </ul>
            <p>Hoe je deze financiële <strong>vergoeding ontvangt</strong> hangt af van lengte van de vertraging(en):
            </p>
            <ul>
              <li>Meer dan 60 minuten: Overschrijving</li>
              <li>Tussen 15 en 59 minuten: Elektronsiche portefeuille (MoBIB-kaart of abonnement)</li>
            </ul>

            <p>De elektronische portefeuille van de NMBS is gelinkt aan jouw MoBIB-kaart of abonnement. De financiële
              vergoeding(en) kan je dan gebruiken voor oa. <strong>korting bij het verlengen van je trein
                abonnement.</strong></p>
          </div>

          <div className="col-md-5">
            <img src={SavingsImage} alt="..."/>
          </div>
        </div>


      </div>
    </section>

    <section className="section">
      <div className="container">
        <header className="section-header">
          <h2>Hoe werkt weertelaat.be?</h2>
          <hr/>
          <p className="lead">We automatiseren het proces om een compensatieaanvraag in te dienen bij de officiële
            instanties.</p>
        </header>

        <div className="row gap-y text-center">

          <div className="col-md-6 col-xl-4 feature-1">
            <h5>Registreer trein trajecten</h5>
            <p className="">Registreer makkelijk trein trajecten waarvoor je compensatie wil ontvangen. Je kan voor
              elk traject een frequentie instellen. </p>
          </div>

          <div className="col-md-6 col-xl-4 feature-1">
            <h5>Beheer vertragingen online</h5>
            <p className="">Alle vertragingen op jouw registreerde trajecten worden automatisch toegevoegd aan
              je account. Je kan ook manueel vertragingen toevoegen via het dashboard.</p>
          </div>

          <div className="col-md-6 col-xl-4 feature-1">
            <h5>Ontvang compensatie</h5>
            <p className="">Genereer een officiële compensatieaanvraag via het dashboard. Je kan de
              aanvraag downloaden of digitaal doorsturen naar de NMBS.</p>
          </div>

        </div>

      </div>
    </section>

    <section className="section text-center bg-light">
      <div className="container">

        <header className="section-header">
          <h2>Gratis en volledig vrijblijvend registreren</h2>
          <hr/>
          <p className="lead">Weertelaat.be is binnenkort beschikbaar voor alle geregistreerde
            gebruikers. <strong>Momenteel verzamelt weertelaat.be alle vertragingen tussen stations van mensen die zich
              hebben ingeschreven.</strong> Schrijf je dus vandaag nog om ervoor te zorgen dat jouw vertragingen kunnen
            worden gebruikt voor je compensatie aanvraag bij de NMBS.</p>
        </header>
        <RegistrationCardForm />

      </div>
    </section>


    <section className="section bg-green py-8">
      <div className="container">
        <div className="row">
          <div className="col-md-12 pb-3">
            <h2 style={{color: 'white'}}>Gratis en vrijblijvend registreren</h2>
          </div>
          <RegistrationCardForm />
        </div>
      </div>
    </section>
  </Layout>
)

export default TestPage
